// JS libraries included in plugins.min.js
jQuery(window).setBreakPoints([360,540,768,1024,1280,1920]);

// Detects Touch Event
window.addEventListener('touchstart',function onFirstTouch(){
	document.documentElement.className += " touch";
	window.removeEventListener('touchstart', onFirstTouch, false);
}, false);

// Global Variables - WordPress Theme information
var WPTHEME = '/wp-content/themes/leavened-v2/';
var DOMAIN = location.protocol + "//" + location.host;

// Global Variables - Page/Viewport Heights
var pageHeight;
var viewHeight;
var menuHeight;

// Declares global variable for ScrollMagic Controller
var ScrollMagicConroller;

// Global Variables - Vimeo Player for Modal
var vimeoModalPlayer;

// jQuery Document Ready
var $ = jQuery;
jQuery(document).ready(function($){

	// Sets Element Height Values
	pageHeight = $('body').outerHeight();
	viewHeight = vhCheck().vh;
	menuHeight = $('header .container').outerHeight();

	initScrollMagic();
	initMobileMenu();
	initSearchMenu();
	initScrollMenu();
	initOpenModal();

	if (isPage('home')){
		//do something
	}
	if (isPage('post-type-archive-resource')){
		//do something
	}
	if (isPage('about-us')){
		initCareers();
	}
	if (isPage('product')){
		initLoadMore();
	}
	if (isPage('contact-us')){
		initContact();
	}
	if (isPage('request-a-demo')){
		initRequestDemo();
	}
	if (isPage('faq-listing')){
		initFAQ();
	}
	if (isPage('solutions')){
		initCarousel();
	}
	if (isPage('marketing-listing')) {
		initABC();
	}

});

// =====================================================================
// ABC of Marketing - Drawer Expand
// =====================================================================
function initABC(){
	$('#abc-listing article .title').click(function(){
		var item = $(this).closest('article');

		if (!item.hasClass('open')){
			item.addClass('opening');
			item.find('.drawer, .abc-icon-wrp').slideDown(400,function(){
				item.addClass('open').removeClass('opening');
			});
		} else {
			item.find('.drawer, .abc-icon-wrp').slideUp(400,function(){
				item.removeClass('open');
			});
		}
	});

}

// =====================================================================
// Solutions - Carousel Slider
// =====================================================================
function initCarousel(){
	$('.slick-slider').slick({
		dots: true,
		arrows: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: true
	 });
}

// =====================================================================
// Product - MMM Load More Table
// =====================================================================
function initLoadMore(){
	$('#compare .flextablewrapper button').click(function(){
		$('#compare .flextablewrapper').removeClass('hidden');
	});
}



// =====================================================================
// FAQ - Listing Expand
// =====================================================================
function initFAQ(){
	$('#faq-listing article .title').click(function(){
		var item = $(this).closest('article');

		if (!item.hasClass('open')){
			item.addClass('opening');
			item.find('.drawer').slideDown(400,function(){
				item.addClass('open').removeClass('opening');
			});
		} else {
			item.find('.drawer').slideUp(400,function(){
				item.removeClass('open');
			});
		}
	});
}


// =====================================================================
// Global - Search Menu in Navigation & Search Result Page Hero
// =====================================================================
function initSearchMenu(){
	setupOpenButton();
	$('header button.close').click(function(){
		var drawer = $(this).closest('.drawer');
		drawer.addClass('closing').removeClass('expand');
		setTimeout(function(){
			drawer.removeClass('closing');
			$('header #searchField').val('');
		 }, 250);
	});
	$('header #searchField').keypress(function(e){
		var key = e.which;
		if(key == 13){
			submitSearch($('header #searchField').val());
			return false;
		 }
	});

	// Search Result Page
	if (isPage('search-results')){
		$('.searchFieldWrapper input#searchFieldHero').keypress(function(e){
			var key = e.which;
			if(key == 13){
				submitSearch($('.searchFieldWrapper input#searchFieldHero').val());
				return false;
			 }
		});
		$('.searchFieldWrapper button').click(function(){
			submitSearch($('.searchFieldWrapper input#searchFieldHero').val());
		});
	}

	function setupOpenButton(){
		if ($(window).getBreakPoint() >= 1024){
			$('header button.open').unbind('click').click(function(){
				var drawer = $(this).closest('.drawer');
				if (drawer.hasClass('expand')){
					submitSearch($('header #searchField').val());
				} else {
					drawer.addClass('expand');
				}
			});
		} else {
			$('header button.open').unbind('click').click(function(){
				submitSearch($('header #searchField').val());
			});
		}
	}

	function submitSearch(term){
		if (term != ''){
			var searchTerm = encodeURI(term.toLowerCase().trim().replace(/"/g, ' '));
			window.location.href = DOMAIN + "/?s=" + searchTerm;
		}
	}
	// Resize listener to remove Mobile menu if switching to Desktop
	var lastBP = $(window).getBreakPoint();
	$(window).resize(function(){
		if (lastBP != $(window).getBreakPoint() && $(window).getBreakPoint() >= 1024){
			$('header .search .drawer').removeClass('expand');
			$('header .search #searchField').val('');
			setupOpenButton();
		} else if (lastBP != $(window).getBreakPoint() && $(window).getBreakPoint() <= 768){
			$('header .search #searchField').val('');
			setupOpenButton();
		}
		lastBP = $(window).getBreakPoint();
	});
}


// =====================================================================
// Contact Us - JotForm AJAX Submit
// =====================================================================
function initContact(){

	// JotForm: Contact Select Drop-down
	$('#formContact select').change(function(){
		if ($(this).val() == ""){
			$(this).addClass('placeholder');
		} else {
			$(this).removeClass('placeholder');
		}
	});

	// Validate
	$('#formContact').validate({
		errorPlacement: function (label, element) {
			$(element).parent().addClass("error");
			label.insertAfter(element);
		},
		success: function(label, element) {
				$(element).parent().removeClass("error");
		},
		messages: {
				"name-first": "First name is required",
				"name-last": "Last name is required",
				"email": "Please enter a valid email address",
				"phone": "Please enter a phone number",
				"regarding": "Please select one of the choices above",
				"message": "Please provide us with a brief message"
		},
		submitHandler: function(form) {
			var postData = $(form).serializeArray();
			postData.push({name:'hostname', value:document.location.hostname});

			// Google reCAPTCHA
			grecaptcha.ready(function(){
				grecaptcha.execute('6LfQddUaAAAAAK3IZMI3jfQjQu0tjfnJaxY7xaaR', {action: 'jotformSubmit'}).then(function(token){
					postData.push({name:'token', value:token});
					postData.push({name:'action', value:'jotformSubmit'});
					//console.log(postData);
					$.ajax({
						type: "POST",
						url: WPTHEME+"jotform-contact-us.php",
						data: postData,
						cache: false
					}).done(function(data){
						// GTM: dataLayer Push
						pushGTM('contact-form-submit',{
							'action' : 'form-submit',
							'form_type' : 'contact-us'
						});
						$('#formContact input[type=text]').val('');
						$('#formContact input[type=email]').val('');
						$('#formContact select').prop('selectedIndex',0).addClass('placeholder');
						$('#formContact textarea').val('');
						window.open("/thank-you/","_self");
						//console.log("ajax returned: "+data);
					}).fail(function(xhr, textStatus, errorThrown){
						//console.log("ajax failure: "+textStatus+"|"+errorThrown);
					});

				});
			});

		}
	});

}

// =====================================================================
// Request a Demo - JotForm AJAX Submit
// =====================================================================
function initRequestDemo(){
	//console.log('started: demo request');

	// Validate
	$('#formDemo').validate({
		errorPlacement: function (label, element) {
			$(element).parent().addClass("error");
			label.insertAfter(element);
		},
		success: function(label, element) {
				$(element).parent().removeClass("error");
		},
		messages: {
			"name-first": "First name is required",
			"name-last": "Last name is required",
			"email": "Please enter a valid email address",
			"phone": "Please enter a phone number"
		},
		submitHandler: function(form) {
			var postData = $(form).serializeArray();
			postData.push({name:'hostname', value:document.location.hostname});
			//console.log(form);

			// Google reCAPTCHA
			grecaptcha.ready(function(){
				grecaptcha.execute('6LfQddUaAAAAAK3IZMI3jfQjQu0tjfnJaxY7xaaR', {action: 'jotformSubmit'}).then(function(token){
					postData.push({name:'token', value:token});
					postData.push({name:'action', value:'jotformSubmit'});
					//console.log(postData);

					$.ajax({
						type: "POST",
						url: WPTHEME+"jotform-request-a-demo.php",
						data: postData,
						cache: false
					}).done(function(data){
						// GTM: dataLayer Push
						pushGTM('contact-form-submit',{
							'action' : 'form-submit',
							'form_type' : 'request-a-demo'
						});
						$('#formContact input[type=text]').val('');
						$('#formContact input[type=email]').val('');
						window.open("/thank-you/","_self");
						//console.log("ajax returned: "+data);
					}).fail(function(xhr, textStatus, errorThrown){
						//console.log("ajax failure: "+textStatus+"|"+errorThrown);
					});

				});
			});

		}
	});

}


// =====================================================================
// Scrolling Menu Links
// =====================================================================
function initScrollMenu(){

	// Menu Item Click
	$('a.scrollMenu').click(function(event){
		event.preventDefault();
		// Scrolls to next point
		scrollPage($(this).attr('href'));
	});

}

// =====================================================================
// Footer drawer for Products section
// =====================================================================
function initLowerNav(){
	$('nav#productNav .control').click(function(){
		if (!$('nav#productNav').hasClass('open')){
			$('nav#productNav').addClass('open');
		} else {
			$('nav#productNav').removeClass('open');
		}
	});
}

// =====================================================================
// Scroll Events on the page
// =====================================================================
function initScrollMagic(){

	ScrollMagicConroller = new ScrollMagic.Controller({
		globalSceneOptions: {
			triggerHook: 'onLeave'
		}
	});

	// Section inView Scenes
	var layoutSections = [];
	$('main > section').each(function(key,value){
		var sectionName = $(this).attr('id');
		var selector = '#'+sectionName;
		layoutSections[key] = new ScrollMagic.Scene({
			triggerHook: 'onEnter',
			triggerElement: selector,
			reverse: false
		})
		//.addIndicators()
		.setClassToggle(selector,'inView')
		.addTo(ScrollMagicConroller);
	});

	// Section Background Particles
	var layoutParticles = [];
	$('main > section').each(function(key,value){
		var sectionName = $(this).attr('id');
		var selector = '#'+sectionName;
		layoutParticles[key] = new ScrollMagic.Scene({
			triggerHook: 1,
			triggerElement: selector,
		})
		//.addIndicators()
		.setTween(selector+' .bgParticles', {top: "-40%", ease: Linear.easeNone})
		.addTo(ScrollMagicConroller);
	});

	// Sets duration/offset after all scene have been defined
	updateScenes();

	// Triggers duration/offset update on resize
	$(window).resize(function(){
		updateScenes();
	});

	// Sets duration/offset, updates after scene created
	function updateScenes(){
		pageHeight = $('body').outerHeight();
		viewHeight = vhCheck().vh;
		menuHeight = $('header .container').outerHeight();

		// Updates Section Scenes & Background Particles
		var middleTrigger = (viewHeight/2) * -1;
		for (i=0; i<layoutSections.length; i++){
			selector = "#"+layoutSections[i].triggerElement().getAttribute('id');
			layoutSections[i].offset(middleTrigger);
		}
		for (i=0; i<layoutParticles.length; i++){
			selector = "#"+layoutParticles[i].triggerElement().getAttribute('id');
			layoutParticles[i].offset(viewHeight *-1);
			layoutParticles[i].duration(viewHeight + $(selector).outerHeight());
		}

		ScrollMagicConroller.update(true);
	}

}

// =====================================================================
// Fixed Desktop Menu and Mobile Menu
// =====================================================================
function initMobileMenu(){

	// Mobile: Hamburger Menu
	$('header button.hamburger').click(function(){
		if (!$('html').hasClass('mobileNavOpen')){
			// Locks the background window
			lockWindow(function(){
				$('html').addClass('mobileNavOpen');
				$('header button.hamburger').addClass('open');
			});
		} else {
			// Unlocks the background window
			unlockWindow(function(){
				$('html').removeClass('mobileNavOpen');
				$('header button.hamburger').removeClass('open');
			});
		}
	});

	// Resize listener to remove Mobile menu if switching to Desktop
	var lastBP = $(window).getBreakPoint();
	$(window).resize(function(){
		if (lastBP != $(window).getBreakPoint() && $(window).getBreakPoint() >= 1024){
			unlockWindow(function(){
				$('html').removeClass('mobileNavOpen');
				$('header .hamburger').removeClass('open');
			});
		}
		lastBP = $(window).getBreakPoint();
	});

}

// =====================================================================
// Modal: Team Bio next/prev Navigation
// =====================================================================
function modalNav(direction){
	var indexArray = $('#modal .innerBio').attr('data-index').split('-');
	var indexGroup = indexArray[0];
	var indexCurrent = parseInt(indexArray[1]);
	var indexNext,indexPrev = 0;
	var countGroup = $('#'+indexGroup+' ul.grid li:not(.newRow)').length;

	if (indexCurrent >= countGroup){
		indexNext = 1;
		indexPrev = indexCurrent - 1;
	} else if (indexCurrent == 1){
		indexNext = indexCurrent + 1;
		indexPrev = countGroup;
	} else {
		indexNext = indexCurrent + 1;
		indexPrev = indexCurrent - 1;
	}

	if (direction == 'arrow-left'){
		showItem(indexPrev);
	} else {
		showItem(indexNext);
	}

	// Shows tab and item
	function showItem(index){
		$('#modal .modal-body').scrollTop(0);
		var nextBio = $('#'+indexGroup+' ul.grid li:not(.newRow):nth('+(index - 1)+') .inner').html();
		$('#modal .innerBio').html(nextBio).attr('data-index',indexGroup+'-'+index);
	}
}

// =====================================================================
// About Us - Careers section
// =====================================================================
function initCareers(){
	$('ul.jobs li .title').click(function(){
		var item = $(this).closest('li');

		if (!item.hasClass('open')){
			item.find('.description').slideDown(400,function(){
				item.addClass('open');
			});
		} else {
			item.find('.description').slideUp(400,function(){
				item.removeClass('open');
			});
		}
	});
}

// =====================================================================
// Video Modals Triggers
// =====================================================================
function initOpenModal(){

	$('.modalTrigger').click(function(event){
		event.preventDefault();
		var modalView = $(this).data('modal');

		if (modalView !== ''){
			// Vimeo Video Player
			if (modalView == 'viewVideo'){
				var vimeoID = $(this).data('vimeo');
				if (vimeoID !== undefined){
					vimeoModalPlayer = new Vimeo.Player($('#modal #video'),{id:vimeoID});
					vimeoModalPlayer.loadVideo(vimeoID).then(function(){
						openModal('view-video');
						vimeoModalPlayer.play();
					});
				}
			}
			// Bio
			else if (modalView == 'viewBio'){
				var group = $(this).closest('ul.grid').attr('data-group');
				var index = $(this).closest('li').index() + 1;
				openModal('view-bio',$(this).html(),group+"-"+index);
			}
		}
	});

}

// =====================================================================
// Global Functions: Modal Open/Close, Window Lock/Unlock, Window Scroll
// =====================================================================
function openModal(modalView,modalInner,modalIndex){
	// Pauses scrollMagic (Desktop)
	if ($(window).getBreakPoint() >= 1024){
		ScrollMagicConroller.enabled(false);
		ScrollMagicConroller.update(true);
	}

	// Locks the background window and opens modal
	lockWindow(function(){
		vhCheck();
		$('#modal').addClass(modalView);
		if (modalInner !== ''){
			$('#modal .innerBio').html(modalInner);
		}
		if (modalIndex !== ''){
			$('#modal .innerBio').attr('data-index',modalIndex);
		}
		if (modalView == 'view-bio'){
			$('#modal .modal-buttons .arrow-left').unbind('click').click(function(){
				modalNav('arrow-left');
			});
			$('#modal .modal-buttons .arrow-right').unbind('click').click(function(){
				modalNav('arrow-right');
			});
		}
		$('html').addClass('showModal');
	});

	// Close Modal Button Listener
	$('#modal .modal-box .close').unbind('click').click(function(event){
		event.preventDefault();
		closeModal();
	});
}
function closeModal(){
	// Unlocks the background window and closes modal
	unlockWindow(function(){
		$('html').removeClass('showModal');

		// Stops video if enabled
		if (typeof vimeoModalPlayer != 'undefined'){
			vimeoModalPlayer.pause();
			vimeoModalPlayer.setCurrentTime(0);
		}

		// wait for the modal to fade out
		setTimeout(function(){
			$('#modal').attr('class','');
			$('#modal .innerBio').html('').attr('data-index','');
		}, 250);

		// Restarts scrollMagic (Desktop)
		if ($(window).getBreakPoint() >= 1024){
			ScrollMagicConroller.enabled(true);
			ScrollMagicConroller.update(true);
		}
	});
}
function lockWindow(callback){
	// Locks the background window
	var scrollPos = $(window).scrollTop();
	$('body').data('scrollPos',scrollPos);
	scrollPos = scrollPos * -1;
	$('html').addClass('noScroll').css('top',scrollPos+'px');
	if(callback !== undefined){
		callback();
	}
}
function unlockWindow(callback){
	// Unlocks the background window
	$('html').removeClass('noScroll').css('top','');
	$(window).scrollTop($('body').data('scrollPos'));
	if(callback !== undefined){
		callback();
	}
}
function scrollPage(scrollTo,callback){
	var menuHeight = $('header').outerHeight();
	var scrollPos = 0;

	// Calculates Scroll Position
	if (scrollTo == "#top"){
		scrollPos = 0;
	} else {
		scrollPos = $(scrollTo).offset().top - menuHeight;
	}

	// Scroll down
	$('html, body').animate({
		scrollTop: scrollPos
	}, {duration: 500, easing: 'linear'}).promise().then(function(){
		if(callback !== undefined){
			callback();
		}
   });
}

// =====================================================================
// Global Function watch for element in the DOM (10 sec max retry)
// =====================================================================
function elementReady(selector,callback,count){
	if(!count){count=0;}
	if (document.querySelector(selector)){
		callback();
	} else {
		setTimeout(function(){
			if(count<=20) {
				count++;
				elementReady(selector,callback,count);
			} else {return;}
		}, 500);
	}
}

// ===================================================================
// Global Function to tell what page is viewed based on body class/ID
// ===================================================================
function isPage(a){
	if($('body#'+a).length){return true;}
	else if ($('body').hasClass(a)){return true;}
	else if ($('body').hasClass('page-'+a)){return true;}
	else if ($('body').hasClass('single-'+a)){return true;}
	else{return false;}
}

// ===================================================================
// Global Function to event and properties object to GTM dataLayer
// ===================================================================
function pushGTM(eventName,eventProperties){
	if (typeof window.dataLayer !== 'undefined'){
		if (typeof eventName === 'string' && typeof eventProperties === 'object'){
			eventName = eventName.replace(' ','-').replace('_','-');
			window.dataLayer.push({
				'event': 'JS - '+eventName,
				'js-event': eventProperties
			});
		}
	}
}